/* eslint-disable react/no-direct-mutation-state */
import React, { useState, useEffect, useLayoutEffect, useCallback } from "react";
import { Usuario } from "Models";

import { UserHelper, InteractionHelper, APIHelper } from "Helper";

import { Box, Typography, ButtonGroup, Button, Divider, Chip, Grid } from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { mdiPencil, mdiContentSaveEdit, mdiPencilRemove, mdiAt, mdiFire } from "@mdi/js";

import { ProfilePicture, InputCustom, SvgIcon } from "Components";

import TextField from "@mui/material/TextField";

import styles from "./style.module.scss";

import ReferralLink from "./ReferralLink";
import DefinitionsGifts from "./DefinitionsGifts";
import Voucher from "./voucher";
import ShippingTracking from "./ShippingTracking";

const Profile: React.FC = () => {
	const [carregando, setCarregando] = useState(true);
	const [isEditProfile, setIsEditProfile] = useState(false);
	const [user, setUser] = useState(new Usuario());
	const [editProfile, setEditProfile] = useState<{
		atSign: string;
		nome: string;
		email: string;
		telefone: string;
		descricao: string;
	}>({
		atSign: "",
		nome: "",
		email: "",
		telefone: "",
		descricao: "",
	});

	const [voucher, setVoucher] = useState("");
	const [voucherResgatado, setVoucherResgatado] = useState("");
	const [showMsg, setShowMsg] = useState(false);

	useLayoutEffect(() => {
		UserHelper.getUser()
			.then((user) => {
				setUser(() => user);
				const { nome = "", email = "", telefone = "", descricao = "", atSign = "", referenceCode = "" } = (user as any) ?? {};

				setEditProfile((prev) => {
					return { nome, email, telefone, descricao, atSign };
				});

				setCarregando(false);
			})
			.catch(() => {
				setUser(() => new Usuario());
				setCarregando(true);
			});
	}, []);

	const saveInfoProfile = (validAtSign?: boolean) => {
		const { nome, email, telefone, descricao, atSign } = editProfile;
		if (!nome || !email || !telefone || !atSign) {
			InteractionHelper.toast("Existem campos inválidos! Tente novamente.", null, "error");
			return;
		}

		setCarregando(true);

		if (atSign !== user.atSign && !validAtSign) {
			UserHelper.atSignAvailable(atSign).then((v) => {
				if (v === true) {
					InteractionHelper.toast("Nome de usuário já existente!", null, "error");
					setCarregando(false);
					return;
				}
				saveInfoProfile(true);
			});

			return;
		}

		const updateUser = new Usuario().parse(user.path, user.toJson());
		updateUser.nome = nome;
		updateUser.email = email;
		updateUser.telefone = telefone;
		updateUser.descricao = descricao;
		updateUser.atSign = atSign;

		UserHelper.salvarUsuario(updateUser)
			.then((user) => {
				setUser(() => user);
				const { nome, email, telefone, descricao, atSign } = user;

				setEditProfile((prev) => {
					return { nome, email, telefone, descricao, atSign };
				});

				setIsEditProfile(false);

				InteractionHelper.toast("Salvo com sucesso", null, "success");
			})
			.catch(() => {
				InteractionHelper.toast("Erro ao tentar salvar!", null, "error");
			})
			.finally(() => {
				setCarregando(false);
			});
	};

	const cancelEditProfile = () => {
		const { nome, email, telefone, descricao, atSign } = user;
		setEditProfile((prev) => {
			return { nome, email, telefone, descricao, atSign };
		});
		setCarregando(false);
		setIsEditProfile(false);
	};

	const editInput = useCallback(
		(prop: string, value: any, valid?: boolean) => {
			if (!isEditProfile || carregando) {
				return;
			}

			setEditProfile((prev) => {
				return {
					...prev,
					...Object.fromEntries([[prop, valid ? value : null]]),
				};
			});
		},
		[isEditProfile, carregando],
	);

	const uploadProfilePicture = () => {
		setCarregando(true);

		InteractionHelper.uploadImage({ imageSize: 250, aspectRatio: "1:1" }, (value) => {
			if (typeof value !== "string") {
				setCarregando(false);
				return;
			}

			UserHelper.uploadProfilePicture(user, value)
				.then((user) => {
					setUser(() => user);
					InteractionHelper.toast("Foto alterada com sucesso", null, "success");
				})
				.catch(() => {
					InteractionHelper.toast("Erro ao tentar salvar a foto de perfil!", null, "error");
				})
				.finally(() => {
					setCarregando(false);
				});
		});
	};

	const { path, fotoPerfil, atSign, nome, email, telefone, descricao, dataCriacao, referenceCode } = user;

	let idUser = path ? path.split("/").pop() : null;
	let data = dataCriacao ? new Date(dataCriacao).toLocaleDateString() : null;

	return (
		<div className={styles["ProfilePage"]}>
			<div className={styles["ProfilePage-picture"]}>
				<ProfilePicture
					src={fotoPerfil}
					onClick={uploadProfilePicture}
					insignia={"wings"}
					points={40}
					size={120}
					loading={carregando}
				/>
				<div className={styles["sponsorDiv"]}>
					<Button
						className={styles["sponsorButton"]}
						disabled
						variant="contained"
						startIcon={<SvgIcon path={mdiFire} />}
						onClick={() => {
							InteractionHelper.toast("Complete seu perfil e receba 100 de XP", null, "info");
						}}
					>
						Perfil de investidor
					</Button>
				</div>

				<Voucher user={user} />

				{/* <div className={styles["temporary-voucher"]}>
					<TextField
						label="Insira seu Voucher"
						variant="outlined"
						value={voucher}
						onChange={(e) => setVoucher(e.target.value)}
					/>
					<Button
						variant="contained"
						color="primary"
						onClick={handleVoucherResgate}
					>
						Resgatar Voucher
					</Button>
					<div className={styles["rescued-voucher"]}>{showMsg ? <p>Voucher resgatado com sucesso: {voucherResgatado}</p> : <></>}</div>
				</div> */}
			</div>
			<Box
				className={styles["ProfilePage-container"]}
				sx={{ flexGrow: 1 }}
				alignItems="stretch"
			>
				<Grid
					container
					rowSpacing={0}
					columnSpacing={4}
				>
					<Grid
						item
						xs={12}
						style={{ marginBottom: "20px" }}
					>
						<Divider textAlign="center">
							<Chip label="Informações de Usuário" />
						</Divider>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
					>
						<div className={styles["ProfilePage-grid-item"]}>
							<InputCustom
								label="Usuário"
								helperText=" "
								value={(isEditProfile ? editProfile.atSign : atSign ? atSign : "") || ""}
								validate={/^[a-z0-9\.\_\@\#\-\+\&\$]{8,}$/i}
								onChange={(value, valid) => editInput("atSign", value, valid)}
								startAdornment={<SvgIcon path={mdiAt} />}
								readOnly={!isEditProfile}
								disabled={carregando}
							/>
							<InputCustom
								label="Plano"
								helperText=" "
								value={"Prata"}
								readOnly
								disabled={isEditProfile || carregando}
							/>
							<InputCustom
								label="ID"
								helperText="Não compartilhe o ID de usuário"
								value={idUser ?? ""}
								type={"password"}
								readOnly
								disabled={isEditProfile || carregando || !idUser}
							/>
						</div>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
					>
						<div className={styles["ProfilePage-grid-item"]}>
							<InputCustom
								label="Nome"
								helperText=" "
								value={(isEditProfile ? editProfile.nome : nome) || ""}
								onChange={(value, valid) => editInput("nome", value, valid)}
								readOnly={!isEditProfile}
								disabled={carregando}
							/>
							<InputCustom
								label="E-mail"
								helperText=" "
								value={(isEditProfile ? editProfile.email : email) || ""}
								type="email"
								validate
								onChange={(value, valid) => editInput("email", value, valid)}
								readOnly={!isEditProfile}
								disabled={true}
							/>
							<InputCustom
								label="Telefone"
								helperText=" "
								value={(isEditProfile ? editProfile.telefone : telefone) || ""}
								type="tel"
								validate={false}
								onChange={(value, valid) => editInput("telefone", value, valid)}
								readOnly={!isEditProfile}
								disabled={true}
							/>
						</div>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
					>
						<div className={styles["ProfilePage-grid-item"]}>
							<InputCustom
								label="Descrição"
								helperText=" "
								value={(isEditProfile ? editProfile.descricao : descricao) || ""}
								onChange={(value, valid) => editInput("descricao", value, valid)}
								multiline
								maxRows={5}
								minRows={5}
								readOnly={!isEditProfile}
								disabled={carregando}
							/>
							<InputCustom
								label="Data de criação"
								helperText=" "
								value={data || ""}
								readOnly
								disabled={isEditProfile || carregando || !data}
							/>
						</div>
					</Grid>

					<Grid
						item
						md={12}
						style={{
							paddingTop: "20px",
							paddingBottom: "20px",
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<ButtonGroup>
							{isEditProfile && (
								<LoadingButton
									loading={carregando}
									loadingPosition="start"
									disabled={carregando}
									variant="outlined"
									startIcon={<SvgIcon path={mdiContentSaveEdit} />}
									onClick={() => saveInfoProfile(false)}
								>
									Salvar
								</LoadingButton>
							)}

							{isEditProfile && (
								<Button
									disabled={carregando}
									variant="outlined"
									startIcon={<SvgIcon path={mdiPencilRemove} />}
									onClick={() => cancelEditProfile()}
								>
									Cancelar
								</Button>
							)}

							{!isEditProfile && (
								<Button
									disabled={carregando}
									variant="contained"
									startIcon={<SvgIcon path={mdiPencil} />}
									onClick={() => {
										setIsEditProfile((prev) => !prev);
									}}
								>
									Editar
								</Button>
							)}
						</ButtonGroup>
					</Grid>

					<Grid
						item
						xs={12}
						style={{ marginBottom: "20px" }}
					>
						<Divider textAlign="center">
							<Chip label="Brinde iVipCoin" />
						</Divider>
					</Grid>

					{/* <Grid
						item
						xs={12}
					>
						<DefinitionsGifts
							disabled={carregando}
							user={user}
							updateUser={() => {}}
						/>
					</Grid>

					<Grid
						item
						xs={12}
					>
						<ShippingTracking />
					</Grid> */}

					{/* 
					<Grid
						item
						xs={12}
						style={{ marginBottom: "20px" }}
					>
						<Divider textAlign="center">
							<Chip label="Indicações" />
						</Divider>
					</Grid>

					<Grid
						item
						xs={12}
					>
						<ReferralLink
							user={user}
							referenceCode={referenceCode}
						/>
					</Grid> */}
				</Grid>
			</Box>
		</div>
	);
};

export default Profile;
